import assign from 'lodash/assign';

// あ A い I う U え E お O.
// か KA き KI く KU け KE こ KO.
// さ SA し SHI す SU せ SE そ SO.
// た TA ち CHI つ TSU て TE と TO.
// な NA に NI ぬ NU ね NE の NO.
// は HA ひ HI ふ HU/FU へ HE ほ HO.
// ま MA み MI む MU め ME も MO.
// や YA ゆ YU よ YO.

//     a  i	  u	  e	 o
// ∅	あ	い	う	え	お
// k	か	き	く	け	こ
// s	さ	し	す	せ	そ
// t	た	ち	つ	て	と
// n	な	に	ぬ	ね	の
// h	は	ひ	ふ	へ	ほ
// m	ま	み	む	め	も
// y	や		 ゆ		   よ
// r	ら	り	る	れ	ろ
// w	わ	ゐ		 ゑ	  を
// ん (n)

export function vowels() {
  const vowels = {
    a: 'あ',
    i: 'い',
    u: 'う',
    e: 'え',
    o: 'お'
  };
  return vowels;
}
export function k() {
  const kLetters = {
    ka: 'か',
    ki: 'き',
    ku: 'く',
    ke: 'け',
    ko: 'こ'
  };
  return kLetters;
}
export function s() {
  const sLetters = {
    sa: 'さ',
    shi: 'し',
    su: 'す',
    se: 'せ',
    so: 'そ'
  };
  return sLetters;
}
export function t() {
  const tLetters = {
    ta: 'た',
    chi: 'ち',
    tsu: 'つ',
    te: 'て',
    to: 'と'
  };
  return tLetters;
}
export function n() {
  const nLetters = {
    na: 'な',
    ni: 'に',
    nu: 'ぬ',
    ne: 'ね',
    no: 'の'
  };
  return nLetters;
}
export function h() {
  const hLetters = {
    ha: 'は',
    hi: 'ひ',
    hu: 'ふ', // hu / fu
    he: 'へ',
    ho: 'ほ'
  };
  return hLetters;
}
export function m() {
  const mLetters = {
    ma: 'ま',
    mi: 'み',
    mu: 'む',
    me: 'め',
    mo: 'も'
  };
  return mLetters;
}
export function y() {
  const yLetters = {
    ya: 'や',
    yu: 'ゆ',
    yo: 'よ'
  };
  return yLetters;
}
const rLetters = {};
const wLetters = {};

const allLetters = {
  vowels: vowels(),
  k: k(),
  s: s(),
  t: t(),
  n: n(),
  h: h(),
  m: m(),
  y: y(),
  r: rLetters,
  w: wLetters
};

export function getHiraganaLetters(letterSetsAllowed) {
  let list = {};

  letterSetsAllowed.forEach(element => {
    if (allLetters[element]) list = assign(list, allLetters[element]);
  });

  return list;
}
