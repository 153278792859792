import React from 'react';
import sample from 'lodash/sample';
import { listOfAlphabetCharacters } from '../utils/alphabet';

class Letters extends React.Component {
  myAnswer = React.createRef();

  generateNewQuestion = () => {
    const question = this.props.question;
    //console.log(`question: ${question}`);

    // Retrieve a subset of alphabet characters we wish to concentrate on this session (e.g. vowels only)
    const letterSetsAllowed = this.props.letterSetsAllowed;
    const characters = listOfAlphabetCharacters(this.props.alphabet, {
      letterSetsAllowed
    });

    // Set a new question (no repeats allowed)
    let newQuestion;
    do {
      newQuestion = sample(characters);
    } while (newQuestion === question);

    this.props.setQuestion(newQuestion);
  };

  checkAnswer = event => {
    event.preventDefault();

    const question = this.props.question;
    const answer = this.myAnswer.current.value;
    // Retrieve only the subset of characters we want to concentrate on for this study session.
    const characters = listOfAlphabetCharacters(this.props.alphabet, {
      letterSetsAllowed: this.props.letterSetsAllowed
    });

    if (characters[answer] && characters[answer] === question) {
      console.log('Congrats');
      this.generateNewQuestion();
    } else {
      console.log('Try again');
    }

    event.currentTarget.reset();
  };

  componentDidMount() {
    this.generateNewQuestion();
  }
  render() {
    return (
      <form onSubmit={this.checkAnswer}>
        <p className="letter" lang="ja-jp">
          {this.props.question}
        </p>
        <input
          type="text"
          ref={this.myAnswer}
          required
          placeholder=""
          defaultValue=""
        />
        {/* <button type="submit">Guess</button> */}
      </form>
    );
  }
}

export default Letters;
