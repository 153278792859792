import { getHiraganaLetters } from './hiragana';

/**
 * Maybe we want to focus our studies purely on a subset of characters from the chosen alphabet.
 *
 * To do this send over the alphabet in question (Hiragana, Katakana, Kanji) and the allowed letter set as an array.
 *
 * For example only concentrate on the vowels and "k" words in the Hiragana alphabet.
 * ('hiragana', {lettersSetsAllowed: ['vowels', 'k']})
 *
 * @param {*} alphabet
 * @param {*} opts
 */
export function listOfAlphabetCharacters(alphabet, opts) {
  let letters;
  switch (alphabet) {
    case 'hiragana':
      letters = getHiraganaLetters(opts.letterSetsAllowed);
      break;
    default:
      break;
  }

  return letters;
}
