import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import NotFound from './components/NotFound';
import Letters from './components/Letters';
// import logo from './logo.svg';
import './App.css';

class App extends Component {
  state = {
    // letterSetsAllowed: ['vowels', 'k', 's', 't'],
    questionType: 'hiragana',
    question: '',
    answer: '',
    hiragana: {
      letterSetsAllowed: ['vowels', 'k', 's', 't', 'n', 'h', 'm', 'y']
    }
  };

  setQuestion = newQuestion => {
    // const question = this.state.question;
    // console.log(`question: ${question}`);
    this.setState({
      question: newQuestion
    });
  };

  render() {
    return (
      <Router>
        <div className="App">
          <div className="App-header">
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <Letters
                    {...props}
                    alphabet="hiragana"
                    letterSetsAllowed={this.state.hiragana.letterSetsAllowed}
                    question={this.state.question}
                    setQuestion={this.setQuestion}
                  />
                )}
              />
              <Route
                exact
                path="/hiragana"
                render={props => (
                  <Letters
                    {...props}
                    alphabet="hiragana"
                    letterSetsAllowed={this.state.hiragana.letterSetsAllowed}
                    question={this.state.question}
                    setQuestion={this.setQuestion}
                  />
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
